@charset "UTF-8";
@media print {
  @page {
    size: auto;
    margin: 0; }
  body * {
    visibility: hidden;
    -webkit-print-color-adjust: exact; }
  #pages-container, #pages-container * {
    visibility: visible; }
  #pages-container {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0; }
  #pages-container .page-container {
    height: 29.65cm;
    width: 21cm;
    margin: 0 !important;
    box-shadow: none !important; }
    #pages-container .page-container.safari {
      height: 29.35cm; }
    #pages-container .page-container .page {
      margin: 0 !important;
      padding: 1.6cm !important; } }

.pages-container {
  width: 90vw;
  max-width: 21cm; }

.page-container {
  display: flex;
  position: relative;
  width: 100%;
  padding-top: 141%;
  margin: 1vw auto;
  border-radius: 2px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8); }
  .page-container .page {
    padding: 2.5vw;
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0; }
  .page-container .bordered-container {
    width: 100%;
    max-height: 100%;
    flex-grow: 1;
    border: 1px solid #D1D2D3; }
    .page-container .bordered-container .page-header {
      padding: 10px 20px;
      border-bottom: 1px solid #D1D2D3; }
    .page-container .bordered-container .page-body {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center; }
  .page-container .footer {
    height: 10%;
    padding: 2% 0; }
    .page-container .footer .scale-container {
      font-size: 12px;
      line-height: 1.3;
      align-self: flex-end; }
    .page-container .footer .stamp-container {
      position: relative;
      display: flex;
      align-items: center; }
      .page-container .footer .stamp-container::before {
        content: attr(data-value) " m²";
        color: white;
        position: absolute;
        font-size: 13px;
        left: 0;
        width: 25%;
        text-align: right;
        transform: translate(0, -25%); }
  .page-container .separator {
    border-bottom: 1px solid black; }
    .page-container .separator > div {
      top: -17px; }
  .page-container .caption {
    font-size: 10px;
    line-height: 1.2;
    padding-top: 4px; }
